
   @font-face {
    font-family: "Light";
    src: local("PaytoneOne-Regular"),
      url("./fonts/Warownia Black.ttf") format("truetype");
    font-weight: bolder;
   }

   @font-face {
    font-family: "All";
    src: local("PaytoneOne-Regular"),
      url("./fonts/ITC.otf") format("truetype");
    font-weight: lighter;

  }
  
  :root {
    color-scheme: only light;
  }

.Vnavbar-brand{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: aliceblue;
}

.navbar,
.navbar-default {
    background-image: linear-gradient(to bottom,#0b0c0d 0,#0b0c0d 100%) !important;
}

.Vnavbar{
    margin: 10px;
}
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  position: relative
}

.fluid-container{
    height: 50px;
    display: flex;
    justify-content: first baseline;
    align-items: center;
}
.nametitle{
    color: aliceblue;
    font-family: Light;
    font-weight: normal;
    font-size: 5vw;
    /* font-family: Arial, Helvetica, sans-serif; */
}
/* .nav-incontainer{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
} */
.contact-btn{
    padding: 11px;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 35px;
    margin-bottom: 8px;
    background-color: #2a2e30;
    border: 0px;
    color: aliceblue;
    font-family: All;
    font-size: 3vw;
    border-radius: 25px;
}
.gallery-item1, .gallery-item2{
  float: left;
}

.gallery-flex-container{
    display: flex;
  }
  .gallery-item1{
    position: relative;
    width: 65%;
    margin: 20px;
    border-radius: 20px;
    margin-right: 15px;
    transition: width 3s;
  }
  .gallery-item2{
    position: relative;
    width: 35%;
    margin: 20px;
    margin-left: 0px;
    border-radius: 20px;
    transition: width 3s;
}
.gallery-img1{
    height: 85.5vh;
    width: 100%;
    border-radius: 20px;
    /* display: none; */
}
.gallery-vid1{
  /* height: 100%; */
    width: 61vw;
    border-radius: 20px;
    
}
.gallery-img2{
  height: 85.5vh;
    width: 100%;
    border-radius: 20px;
}
.gallery-vid2{
  /* height: 100%; */
    width: 61vw;
    border-radius: 20px;
    /* display: none; */
}
.item-title1{
  margin: 15px;
  margin-bottom: 18px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
}
.item-title2{
  margin: 15px;
  margin-bottom: 18px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
}
.item-title1 h2{
  font-family: All;
  font-size: 40px;
  color: aliceblue;
}
.item-title1 h1{
  font-family: All;
  font-size: 18px;
  color: rgb(218, 218, 218);
}
.item-title2 h2{
  font-family: All;
  font-size: 40px;
  color: aliceblue;
}
.item-title2 h1{
  font-family: All;
  font-size: 18px;
  color: rgb(218, 218, 218);
}
.gallery-item1, .gallery-item2{
  width: 65%; /* Initial width */
  float: left;
  transition: width 1s ease; /* Apply transition to width property */
}
.gallery-item1.expanded {
  width: 100%; /* Target width when expanded */
}
.gallery-item2.expanded {
  width: 100%; /* Target width when expanded */
}
.footer-top{
  display : flex;
  flex-Direction : row ; 
  flex-Wrap : wrap; 
  width : 25%; 
  margin : 25px;
}
.whatsapp-btn{
  padding: 11px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: #dedede;
  border: 0px;
  color: rgb(44, 44, 44);
  font-family: All;
  font-size: 3vw;
  border-radius: 5px;
  height: 8%;
  font-size: 18px;
}
.footer{
  display : flex ;
  align-Items: center ;
}
.whatsapp-img{
  height : 27px ;
  width : 27px;
  margin-right: 5px;

}
.fiverr-img{
  height : 23px ;
  width : 23px;
  margin-right: 5px;
}
.space{
  width: 25px;
}
.page-vid{
  height: 100%;
    width: 60vw;
    border-radius: 20px;
    
}
.place-holder{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.page-img{
  width: 60vw;
  height: 100%;
  border-radius: 20px;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-inner {
  max-width: 95%;
  max-height: 95%;
  overflow: auto;
}

.popup-inner img {
  max-width: 100%;
  max-height: 100%;
}
.page-title{
  margin: 15px;
  margin-bottom: 18px;
  padding: 10px;
  bottom: 0;
  background-color: rgba(48, 48, 48, 0.6);
  border-radius: 20px;
  width: 60%;
}
.page-title h2{
  font-family: All;
  font-size: 40px;
  color: aliceblue;
}
.page-title h1{
  font-family: All;
  font-size: 18px;
  color: rgb(218, 218, 218);
}
.page-btn{
  padding: 11px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 15px;
  /* margin-bottom: 8px; */
  background-color: #000000;
  border: 0px;
  color: rgb(211, 211, 211);
  font-family: All;
  font-size: 3vw;
  border-radius: 5px;
  height: 8%;
  font-size: 18px;
}
.page-icon{
  height : 20px ;
  width : 20px;
  margin-right: 8px;
}
.left-icon{
  height : 35px ;
  width : 35px ;
  margin-Right : 10px;
}
.right-icon{
  height : 35px ;
  width : 35px ; 
  margin-Left : 10px;
}
.mail-btn{
  background-color: #000;
   font-size: 15px;
  color: #fff;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: All;
  margin-top :3vh;
  margin-left: 3vw;
  width: 10vw;
  height: 7vh;
}
.formfeed input, textarea {
  background-color: #000;
  color: #fff;
  border: 1px solid #5a5a5a;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 5px;
  width: 25vw;
}
.feedhead{
  color: #fff ;
   margin :20px;
    font-size: 20px;
    font-family: All;
}
.showcase{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* text-align: center; */
  gap: 10px;
  margin-top: 15px;
}
.showcase-1{
  border-radius: 15px;
  background-color: #00d0d0;
  width: 32%;
  height: 40vh;
  position: relative;
  animation-name: square1;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
  border: 2px solid #000000;
}
.showcase1{
  margin-left: 25px;
}
.showcase-2{
  border-radius: 15px;
  background-color: #ffffff;
  width: 32%;
  height: 40vh;
  position: relative;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border: 2px solid #000000;
  /* animation-direction: reverse; */
}
.showcase2{
  margin-right: 25px;
}
.showcase3{
  margin-left: 25px;
}
.showcase4{
  margin-right: 25px;
}
.showcase-3{
  display: flex;
  position: absolute;
  border-radius: 15px;
  background-color: #00d0d0;
  width: 32%;
  height: 40vh;
  margin: 23vh;
  border: 2px solid #5a5a5a;
}

/* @keyframes square1 {
  0%    {top: 0; left: 0}
  25%   {top: 0; left: 32.7%;}
  50%   {top: 51%; left: 32.7%;}
  75%   {top: 51%; left: 0px;}
  100%  {top: 0px; left: 0px;}
} */
@keyframes square1 {
  10% { top: 0; left: 0;} 
  25% { top: 0; left: 32.7%; } 
  37.5% { top: 0; left: 32.7%; } 
  50% { top: 51%; left: 32.7%; } 
  62.5% { top: 51%; left: 32.7%; }
  75% { top: 51%; left: 0; } 
  87.5% { top: 51%; left: 0; } 
  100% { top: 0; left: 0; }
}
@keyframes square2 {
  0% { top: 0; right: 0; } 
  15% { top: 0; right: 32.7%; } 
  27.5% { top: 0; right: 32.7%; } 
  40% { top: 51%; right: 32.7%; } 
  52.5% { top: 51%; right: 32.7%; } 
  65% { top: 51%; right: 0; } 
  77.5% { top: 51%; right: 0; }
  90% { top: 0; right: 0; }
}
@keyframes square3 {
  0% { bottom: 0; left: 0; } 
  15% { bottom: 0; left: 32.7%; } 
  27.5% { bottom: 0; left: 32.7%; } 
  40% { bottom: 51%; left: 32.7%; } 
  52.5% { bottom: 51%; left: 32.7%; } 
  65% { bottom: 51%; left: 0; } 
  77.5% { bottom: 51%; left: 0; } 
  90% { bottom: 0; left: 0;} 
}
@keyframes square4 {
  10% { bottom: 0; right: 0; } 
  25% { bottom: 0; right: 32.7%; } 
  37.5% { bottom: 0; right: 32.7%; } 
  50% { bottom: 51%; right: 32.7%; } 
  62.5% { bottom: 51%; right: 32.7%; } 
  75% { bottom: 51%; right: 0; } 
  87.5% { bottom: 51%; right: 0; } 
  100% { bottom: 0; right: 0; } 
}
.round-img{
    width: 75px;
    height: 75px;
    border-radius: 200px;
    overflow: "hidden";
    border-width: 3;
    border-color: "red";
}
.profile-div{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 25px;  
}
.profile-title{
  font-family: Light;
}
.profile-sub{
  font-family: All;
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  margin-top: -5px;
  margin-left: 5px;
}
.profile-des{
  font-family: All;
  font-size: 15px;
  color: #404040;
}
.profile-dess{
  font-family: All;
  font-size: 15px;
  color: #404040;
}
.item-profile-title1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  height: 3vh;
}
.item-profile-title1 h1{
  margin-top: 10px;
  font-family: All;
  font-size: 12px;
  color: aliceblue;
}
.about-us{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.about-title{
  color: rgb(255, 252, 249);
  font-family: Light;
  font-size: 50px;
}
.about-sub{
  color: rgb(216, 216, 216);
  font-family: All;
  font-size: 20px;
}
.about-item-div{
  display: flex;
  justify-content: center;
  height: 35vh;
  width: 80%;
  gap: 15px;
  margin-top: 35px;
  margin-bottom: 35px;

}
.about-item{
  display: flex;
  flex-direction: column;
  justify-content: center ;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  height: 35vh;
  width: 50%;
  padding: 25px;
}
.about-item img{
  height: 65px;
  width: 65px;
}
.about-item h2{
  font-family: All;
  font-size: 23px;
  margin-top: 10px;
  font-weight: bolder;
}
.about-item span{
  font-family: All;
  text-align: center;
  color: #626262;
  font-size: 15px;
}
.point-divv{
  display: flex;
  border-radius: 45px;
  width: 80%;
  height: 5.5rem;
  background-color: #c278ff8d;
  align-items: center;
  margin-bottom: 20px;
}
.point-div{
  display: flex;
  border-radius: 45px;
  width: 70%;
  height: 5.5rem;
  background-color: #c278ff8d;
  align-items: center;
  margin-bottom: 20px;
}
.point-in{
  display: flex;
  justify-content: center;
  border-radius: 45px;
  width: 5.5rem;
  height: 5.5rem;
  background-color: #ffffff;
  align-items: center;
}
.points{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.points-title{
  color: rgb(255, 252, 249);
  font-family: Light;
  font-size: 40px;
  word-spacing: 5px;
  margin-bottom: 15px;
}
.points-sub{
  color: rgb(216, 216, 216);
  font-family: All;
  font-size: 16px;
  text-align: center;
  width: 65%;
  margin-bottom: 35px;
}
.points-main-title{
  color: rgb(216, 216, 216);
  font-family: All;
  font-size: 28px;
}
.points-main-sub{
  color: rgb(223, 223, 223);
  font-family: All;
  font-size: 16px;
}
.points-img{
  width: 50px;
  height: 50px;
}
.points-indiv{
  margin-left: 15px;
}
.accord-btn {
  padding: 8px 20px;
  background-color: rgba(52, 52, 52, 0.0);
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 25px;
  font-family: Light;
  font-size: 21px;
  word-spacing: 4px;
  font-weight: lighter;

}
.accord-p {
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  font-family: All;
  margin: 20px;
}
.accord-div:hover {
  background-color: #020202;
}
.accord-div:active {
  transition: max-height 0.3s ease-out;
}
.accord-div:focus {
  outline: none;
}
.accord-div{
  display: flex;
   flex-wrap: wrap;
   width: 90%;
   margin: 15px;
   border-radius: 25px;
   background-color: #2a2e30;
   padding: 8px;
   transition: max-height 0.3s ease-out;

}
/* @keyframes square2 {
  0%    {top: 0; right: 0}
  25%   {top: 0; right: 32.7%;}
  50%   {top: 51%; right: 32.7%;}
  75%   {top: 51%; right: 0px;}
  100%  {top: 0px; right: 0px;}
}
@keyframes square3 {
  0%    {bottom: 0; left: 0}
  25%   {bottom: 0; left: 32.7%;}
  50%   {bottom: 51%; left: 32.7%;}
  75%   {bottom: 51%; left: 0px;}
  100%  {bottom: 0px; left: 0px;}
}
@keyframes square4 {
  0%    {bottom: 0; right: 0}
  25%   {bottom: 0; right: 32.7%;}
  50%   {bottom: 51%; right: 32.7%;}
  75%   {bottom: 51%; right: 0px;}
  100%  {bottom: 0px; right: 0px;}
} */
/* .gallery-item1.hovered {
  width: 65%;
}

.gallery-item2.hovered {
  width: 35%;
}  */
 /* .gallery-item1:hover{
  width: 65%;
}

.gallery-item2:hover{
  width: 100%;
  
} */

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
    .nametitle {
      font-size: 33px;
    }
    .contact-btn{
        font-size: 14px;
    }
    .item-profile-title1{
      display: none;
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    .nametitle {
         /* font-size: 22px; */
         font-size: 1.4rem;
    }
    .contact-btn{
        /* font-size: 11px; */
        font-size: 0.7rem;
    }
    .gallery-flex-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .gallery-item2{
        width: auto;
        margin: 1.3rem;
        /* margin: 20px; */
      }
      .gallery-item1{
        width: auto;
        margin: 1.3rem;
        margin-bottom: 5px;
      }
      .gallery-vid1{
          /* width: 23.9rem; */
          /* width: 90vw; */
          width: 100%;
      }
      .gallery-vid2{
          /* width: 23.9rem; */
          /* width: 90vw; */
          /* display: block; */
          width: 100%;

      }
      .gallery-img2{
        display: none;
      }
      .item-title1 h2{
        font-size: 1.3rem;
        /* font-size: 21px; */
      }
      .item-title1 h1{
        font-size: 0.75rem;
        /* font-size: 12px; */
      }
      .item-title2 h2{
        font-size: 1.3rem;
        /* font-size: 21px; */
      }
      .item-title2 h1{
        font-size: 0.75rem;
        /* font-size: 12px; */
      }
      .item-title1{
        padding-bottom: 0.17rem;
        /* padding-bottom: 3px; */
      }
      .item-title2{
        padding-bottom: 0.17rem;
        /* padding-bottom: 3px; */
      }
      .footer-top{
        width: 100%;
      }
      .footer{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .whatsapp-btn{
        margin-top: -1.56rem;
        /* margin-top: -25px; */
        font-size: 0.75rem;
        /* font-size: 13px; */
        padding: 0.313rem;
        /* padding: 5px; */
        padding-right: 0.5rem;
        /* padding-right: 8px; */
        margin-right: 0.3rem;
      }
      .whatsapp-img{
        height: 1.563rem;
        /* height: 25px; */
        width: 1.563rem;
        /* width: 25px; */
      }
      .fiverr-img{
        height: 1.313rem;
        /* height: 21px; */
        width: 1.313rem;
        /* width: 21px; */
      }
      .left-icon{
        height: 1.25rem;
        /* height: 20px; */
        width: 1.25rem;
        /* width: 20px; */
        margin-right: 5px;
        /* margin-right: 5px; */
      }
      .right-icon{
        height: 1.25rem;
        /* height: 20px; */
        width: 1.25rem;
        /* width: 20px; */
        margin-left: 0.313rem;
        /* margin-left: 5px; */
      }
      .page-vid{
        height: 100%;
          /* width: 20rem; */
          width: 85vw;
          border-radius: 20px;
          /* border-radius: 20px; */
          
      }
      .page-img{
        width: 85vw;
        /* width: 85vw; */
        height: 100%;
        border-radius: 20px;
        /* border-radius: 20px; */
      }
      .page-title{
        margin: 15px;
        /* margin: 15px; */
        margin-bottom: 18px;
        /* margin-bottom: 18px; */
        padding: 10px;
        /* padding: 10px; */
        bottom: 0;
        background-color: rgba(48, 48, 48, 0.6);
        border-radius: 20px;
        /* border-radius: 20px; */
        width: 95%;
      }
      .page-title h2{
        font-family: All;
        font-size: 32px;
        /* font-size: 32px; */
        color: aliceblue;
      }
      .page-title h1{
        font-family: All;
        font-size: 16px;
        /* font-size: 16px; */
        color: rgb(218, 218, 218);
      }
      .page-btn{
        padding: 10px;
        padding-left: 6px;
        padding-right: 5px;
        margin-right: 6px;
        /* padding: 10px;
        padding-left: 6px;
        padding-right: 5px;
        margin-right: 6px; */
        background-color: #000000;
        border: 0px;
        color: rgb(211, 211, 211);
        font-family: All;
        border-radius: 5px;
        /* border-radius: 5px; */
        height: 8%;
        font-size: 13px;
        /* font-size: 13px; */
      }
      .page-icon{
        height : 15px ;
        width : 15px;
        margin-right: 4px;
        /* height : 15px ;
        width : 15px;
        margin-right: 4px; */
      }
      .formfeed{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .formfeed input {
        width: 96%;
      }
      .formfeed textarea {
        width: 100%;
        margin-left: -10px;
        /* margin-left: -10px; */
      }
      .mail-btn{
        width: 25vw;
        font-size: 13px;
        height: 4.5vh;
        margin-left: 27%;
        margin-top: 10px;
        /* width: 25vw;
        font-size: 13px;
        height: 4.5vh;
        margin-left: 27%;
        margin-top: 10px; */
      }
      .feedhead{
        font-size: 16px;
        /* font-size: 16px; */
      }
      @keyframes square1 {
        0% { z-index: 0; height: 14vh; width: 42%; } 
        25% { z-index: 1; height: 15vh; width: 43%; } 
        50% { z-index: 1; height: 15vh; width: 43%; } 
        75% { z-index: 1; height: 15vh; width: 43%; } 
        100% { z-index: 0; height: 14vh; width: 42%; } 
      }
      .showcase-1{
        width: 42%;
        /* height: 14vh; */
        height: 7.9rem;
        animation-duration: 0s;
      }
      .showcase-2{
        width: 42%;
        height: 7.9rem;
        /* height: 14vh; */
        animation-duration: 0s;
      }
      .showcase-3{
        width: 82%;
        height: 28vh;
        margin: 0.5vh;
        /* height: 28vh;
        margin: 0.5vh; */

      }
      .showcase1 .showcase3{
        margin-left: 0px;
      }
      .showcase2 .showcase4{
        margin-right: 0px;
      }
      .profile-div{
        margin: 10px;
        /* margin: 10px; */
      }
      .round-img{
        width: 3.75rem;
        height: 3.75rem;
        /* width: 60px;
        height: 60px; */
      }
      .profile-title{
        /* font-size: 18px; */
        font-size: 1.1rem;
      }
      .profile-sub{
        font-size: 0.8rem;
        margin-top: -5px;
        margin-left: 2px;
        /* font-size: 13px;
        margin-top: -5px;
        margin-left: 2px; */
      }
      .profile-des{
        display: none;
      }
      .profile-dess{
       font-size: 13px;
       /* font-size: 13px; */
      }
      .about-us{
        margin-top: 10px;
        /* margin-top: 10px; */
        width: 100%;
      }
      .about-title{
        font-size: 2rem;
        /* font-size: 32px; */
      }
      .about-sub{
        font-size: 17px;
        /* font-size: 17px; */
      }
      .about-item-div{
        height: auto;
        width: 100%;
        margin-top: 35px;
        margin-bottom: 15px;
        /* margin-top: 35px;
        margin-bottom: 15px; */
        flex-wrap: wrap;
      }
      .about-item{
        height: 14.5rem;
        width: 45%;
        padding: 1.20rem;
        /* height: 25vh;
        width: 45%;
        padding: 25px; */
      }
      .about-item img{
        height: 45px;
        width: 45px;
        /* height: 45px;
        width: 45px; */
      }
      .about-item h2{
        font-size: 18px;
        margin-top: 10px;
        /* font-size: 18px;
        margin-top: 10px; */
      }
      .about-item span{
        font-size: 14px;
        /* font-size: 14px; */
      }
      .showcase{
        gap: 5px;
      }
      .point-divv{
        height: 5.5rem;
        width: 95%;
      }
      .point-div{
        height: 5.5rem;
        width: 95%;
      }
      .point-in{
        width: 170px;
        height: 5.5rem;
      }
      .points-img{
        width: 45px;
        height: 45px;
      }
      .points-main-title{
        font-size: 18px;
      }
      .points-main-sub{
        font-size: 12px;
      }
      .points-indiv{
        margin-left: 4px;
      }
      .points-title{
        font-size: 28px;
        text-align: center;
        margin-top: 15px;
      }
      .points-sub{
        font-size: 15px;
        width: 90%;
      }
      .accord-btn{
        font-size: 14px;
        word-spacing: 2px;
      }
      .accord-p{
        font-size: 13px;
      }
      .accord-div{
        padding-left: 0px;
      }
  }
  @media screen and (max-width: 370px) {
    .showcase-1{
      width: 41%;
      /* height: 14vh; */
      height: 7.9rem;
      animation-duration: 0s;
    }
    .showcase-2{
      width: 41%;
      height: 7.9rem;
      /* height: 14vh; */
      animation-duration: 0s;
    }
    .showcase-3{
      width: 82%;
      height: 18rem;
      margin: 0.5vh;
      /* height: 28vh;
      margin: 0.5vh; */

    }
    .showcase1 .showcase3{
      margin-left: 0px;
    }
    .showcase2 .showcase4{
      margin-right: 0px;
    }
    .profile-div{
      margin: 10px;
      /* margin: 10px; */
    }
    .round-img{
      width: 3.65rem;
      height: 3.65rem;
      /* width: 60px;
      height: 60px; */
    }
    .profile-title{
      /* font-size: 18px; */
      font-size: 1.0rem;
    }
    .profile-sub{
      font-size: 0.7rem;
      margin-top: -5px;
      margin-left: 2px;
      /* font-size: 13px;
      margin-top: -5px;
      margin-left: 2px; */
    }
    .profile-des{
      display: none;
    }
    .profile-dess{
     font-size: 13px;
     /* font-size: 13px; */
    }
    .item-profile-title1{
      margin-left: 4px;
    }
    .points-main-title{
      font-size: 16px;
    }
    .points-sub{
      font-size: 13px;
      width: 100%;
    }
    .point-divv{
      height: 5.4rem;
      width: 95%;
    }
    .point-div{
      height: 5.4rem;
      width: 95%;
    }
    .point-in{
      width: 160px;
      height: 5.4rem;
    }
    .points-img{
      width: 35px;
      height: 35px;
    }
    .about-item-div{
      height: auto;
      width: 100%;
      margin-top: 35px;
      margin-bottom: 15px;
      /* margin-top: 35px;
      margin-bottom: 15px; */
      flex-wrap: wrap;
    }
    .about-item{
      height: auto;
      width: 45%;
      padding: 1rem;
      /* height: 25vh;
      width: 45%;
      padding: 25px; */
    }
    .about-item img{
      height: 40px;
      width: 40px;
      /* height: 45px;
      width: 45px; */
    }
    .about-item h2{
      font-size: 15px;
      margin-top: 10px;
      /* font-size: 18px;
      margin-top: 10px; */
    }
    .about-item span{
      font-size: 12px;
      /* font-size: 14px; */
    }
    .whatsapp-btn{
      margin-top: -1.56rem;
      /* margin-top: -25px; */
      font-size: 0.70rem;
      /* font-size: 13px; */
      padding: 0.2rem;
      /* padding: 5px; */
      padding-right: 0.4rem;
      /* padding-right: 8px; */
      margin-right: 0.1rem;
    }
    .whatsapp-img{
      height: 1.4rem;
      /* height: 25px; */
      width: 1.4rem;
      /* width: 25px; */
    }
    .fiverr-img{
      height: 1.2rem;
      /* height: 21px; */
      width: 1.2rem;
      /* width: 21px; */
    }
    .contact-btn{
      font-size: 10px;
      padding: 5px;
      padding-right: 10px;
      padding-left: 10px;
      margin-right: 18px;
    }
    .gallery-item1{
      width: 100%;
    }
    .gallery-item2{
      width: 100%;
    }

  }
